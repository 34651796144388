import React, { Component, useState , Fragment} from "react";
import { connect } from "react-redux";
import {createPdf, getContractAddon, getContractById} from "../../../reducers/modules/Contratto";
import {
  getContractList,
  getContractProduct,
  saveContract,
  getCompanyDataFromVat,
  regenerateContractTask,
} from "../../../reducers/modules/Contratto";
import { Loader } from "../../shared/Loader/Loader";
import api from "../../../api/index";
import axios from "axios";
import { FaFilePdf, FaMinus, FaPlus } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { API_CONTRACT_URL } from "../../../config";
import { CgSearch } from "react-icons/cg";

import { MultiSelect } from "react-multi-select-component";
// import {renderExportingStatus} from "../ListaContratti/ListaContratti";


import "./NuovoContratto.css";

const formFields = [
  { label: "vatCode", isRequired: true, searchable: true,  },
  { label: "companyName", isRequired: true, searchable: false },
  { label: "legalRepresentative", isRequired: true, searchable: false },
  { label: "companyType", isRequired: true, searchable: false, type: "select"},
  { label: "taxCode", isRequired: true, searchable: false },
  { label: "phone", isRequired: true, searchable: false },
  { label: "cell", isRequired: true, searchable: false },
  { label: "emails", isRequired: true, searchable: false },
  { label: "pec", isRequired: true, searchable: false },
  { label: "iban", isRequired: false, searchable: false },
  { label: "sdiCode", isRequired: true, searchable: false },
  { label: "operationalContact", isRequired: true, searchable: false },
];

const addressType = [
  { label: "legalAddress", isRequired: true, searchable: false },
  { label: "operativeAddress", isRequired: true, searchable: false },
];
const pubblicaAmministrazione = [
  { label: "isPubblicaAmministrazione", isRequired: false, searchable: false, type: "checkbox"},
  { label: "cig", isRequired: false, searchable: false, conditionalRender: true, type: "text"}
];

const addressField = [
  { label: "legalAddressAddressField", isRequired: true, searchable: false, type:"legalAddress" },
  { label: "legalAddressZipcodeField", isRequired: true, searchable: false, type:"legalAddress" },
  { label: "legalAddressCityField", isRequired: true, searchable: false, type:"legalAddress" },
  { label: "legalAddressProvinceField", isRequired: true, searchable: false, type:"legalAddress" },
  { label: "legalAddressCountryField", isRequired: true, searchable: false, type:"legalAddress" },

  { label: "operativeAddressAddressField", isRequired: true, searchable: false, type:"operativeAddress" },
  { label: "operativeAddressZipcodeField", isRequired: true, searchable: false,type:"operativeAddress" },
  { label: "operativeAddressCityField", isRequired: true, searchable: false, type:"operativeAddress" },
  { label: "operativeAddressProvinceField", isRequired: true, searchable: false, type:"operativeAddress" },
  { label: "operativeAddressCountryField", isRequired: true, searchable: false, type:"operativeAddress" },

]

const companyType = [
  "Società semplice",
  "Società in nome collettivo (s.n.c.)",
  "Società in accomandita semplice (s.a.s.)",
  "Società per azioni (s.p.a.)",
  "Società a responsabilità limitata (s.r.l.)",
  "Società in accomandita per azioni (s.a.p.a.)",
  "Società cooperative",
  "Società di mutuo soccorso",
  "Ditta individuale",
]
class Contratto extends Component {
  state = {
    isNew: true,
    error: false,
    noProductsSelected: false,
    products: [],
    filesName: [],
    contractProduct: {},
    selectedProducts: {},
    loading: false,
    errorString: "",
    errorDelete:false,
    isPubblicaAmministrazione:false,
    isSameAddress:true,
    addon:[],
    addonContract:[],
    selectedAddon:[],
    productPaymentExtended: false,

  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const isNew = this.props.match.params.id === "nuovo";
    if (!isNew) {
      const response = await this.props.getContractById(this.props.match.params.id);
      const contratto = response.data;
      const contractProduct = await this.props.getContractProduct(localStorage.getItem("token"));
      const addon = await this.props.getContractAddon()
      this.setState({
        isNew: false,
        ...contratto,
        contractProduct:contractProduct.data,
        addonContract: addon.data
      });
      this.setSelectedProduct(contratto.products, contractProduct.data)
      this.setState({selectedAddon: contratto.products
          .filter(item => item.codprodotto.slice(0,2) === 'AD')
          .map(item => {
            return {
              ...item,
              label: item.nomeProdotto+ " "+ item.valoreContratto+'€',
              value: item.codprodotto,
            }
          })})
      if(this.state.legalAddressAddressField===this.state.operativeAddressAddressField){
        this.setState({isSameAddress:true})
      } else {
        this.setState({isSameAddress:false})
      }

    } else {
      // Load products list that can be added to the products
      const contractProduct = await this.props.getContractProduct(localStorage.getItem("token"));
      const addon = await this.props.getContractAddon()

      // Initialize default value to avoid null error
      const initialValues = formFields.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.label]: "",
        };
      }, {});
      const addressInitialValues = addressField.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.label]: "",
        };
      }, {});

      const pubblicaAmministrazioneInitialValues = pubblicaAmministrazione.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.label]: "",
        };
      }, {});

      this.setState({ ...initialValues,...addressInitialValues, ...pubblicaAmministrazioneInitialValues, contractProduct: contractProduct.data, addonContract:addon.data, loading: false });
      this.setState({legalAddressCountryField:"Italia", operativeAddressCountryField:"Italia", isSameAddress:true})
    }

    const addonWithLabel = this.state.addonContract.map(addon => {
      return {
        ...addon,
        label: addon.nomeProdotto + " " + addon.valoreContratto + "€",
        value: addon.codprodotto
      }
    })
    this.setState({addonContract: addonWithLabel})
    !!this.state.cig ? this.setState({isPubblicaAmministrazione:true}) : this.setState({isPubblicaAmministrazione:false})
  };

  setSelectedProduct = (contractSelected, contractProduct) => {
    contractSelected.map(productSelected => {
      const category = productSelected.codprodotto.slice(0,2)
      const productToInsert = contractProduct[category]?contractProduct[category].find(product => product.codprodotto==productSelected.codprodotto):null
      if(productToInsert){
        this.setState({selectedProducts:{
            ...this.state.selectedProducts,
            [category]:productToInsert,
          }})
      }
    })

  }

  saveContract = async (contractData, isPriceQuotation) => {
    this.setState({ creatingContract: true });
    const result = await this.props.saveContract(contractData, isPriceQuotation);
    const { payload } = result;
    if (
      payload &&
      (payload.id || (payload.status === 504 && payload.response.message === "redirect_to_list"))
    ) {
      // this.props.history.push("/ListaContratti");
    } else {
      if(payload.status === 409){
        this.setState({errorConcessionario: true})
      } else {
        this.setState({ error: true });
      }
    }
    this.setState({ creatingContract: false });
  };

  goBack = () => {
    if (this.state.isNew) this.props.history.push("/Dispatcher");
    else this.props.history.push("/ListaContratti");
  };

  handleSubmit = async (isPriceQuotation = false, forceRefresh = false) => {

    this.setState({ noProductsSelected: false });
    let contractData = {
      id:this.state["id"]?this.state["id"]:null,
      companyName: this.state["companyName"],
      taxCode: this.state["taxCode"],
      legalRepresentative: this.state["legalRepresentative"],

      companyType:this.state["companyType"],
      vatCode: this.state["vatCode"],
      phone: this.state["phone"],
      cell: this.state["cell"],
      emails: this.state["emails"],
      pec: this.state["pec"],
      iban: this.state["iban"],
      sdiCode: this.state["sdiCode"],
      operationalContact: this.state["operationalContact"],

      legalAddressAddressField:this.state["legalAddressAddressField"],
      legalAddressZipcodeField:this.state["legalAddressZipcodeField"],
      legalAddressProvinceField:this.state["legalAddressProvinceField"],
      legalAddressCountryField:this.state["legalAddressCountryField"],
      legalAddressCityField:this.state["legalAddressCityField"],


      operativeAddressAddressField: this.state.isSameAddress ? this.state["legalAddressAddressField"] : this.state["operativeAddressAddressField"],
      operativeAddressZipcodeField: this.state.isSameAddress ? this.state["legalAddressZipcodeField"] : this.state["operativeAddressZipcodeField"],
      operativeAddressProvinceField: this.state.isSameAddress ? this.state["legalAddressProvinceField"] : this.state["operativeAddressProvinceField"],
      operativeAddressCountryField: this.state.isSameAddress ? this.state["legalAddressCountryField"] : this.state["operativeAddressCountryField"],
      operativeAddressCityField: this.state.isSameAddress ? this.state["legalAddressCityField"] : this.state["operativeAddressCityField"],

      cig:this.state["isPubblicaAmministrazione"]?this.state["cig"]:null,
      note: !!this.state['note'] ? this.state['note'] : "",
      productPaymentExtended: !!this.state['productPaymentExtended'] ? this.state['productPaymentExtended'] : false,

      products: this.state.status === 'CREATED'
        ? this.state.products.filter(item => item.codprodotto.slice(0,2) !== 'AD').filter(item => item.vincolato==="Primario").length!== Object.keys(this.state.selectedProducts).length
          ? Object.values(this.state.selectedProducts).filter(
            (field) => field !== "" && !!field
          ).map(product => {
            const productPresent = this.state.products.find(item => item.codprodotto===product.codprodotto)
            return {
              codprodotto: product.codprodotto,
              valoreContratto: !!productPresent ? productPresent.valoreContratto :product.valoreContratto,
              valoreCompetenza: !!productPresent ? productPresent.valoreCompetenza :product.valoreCompetenza,
              fatturazione: !!productPresent ? productPresent.fatturazione ==='25' ? '25' :"50" : product.fatturazione ==='25' ? '25' :"50",
            }}).filter(item => !item.codprodotto.includes('AD'))
          : this.state.products.map(product => {
            const productPresent = this.state.products.find(item => item.codprodotto===product.codprodotto)
            return {
              codprodotto: product.codprodotto,
              valoreContratto: !!productPresent ? productPresent.valoreContratto :product.valoreContratto,
              valoreCompetenza: !!productPresent ? productPresent.valoreCompetenza :product.valoreCompetenza,
              fatturazione: !!productPresent ? productPresent.fatturazione :product.fatturazione,
            }}).filter(item => !item.codprodotto.includes('AD'))
        : Object.values(this.state.selectedProducts).filter(
          (field) => field !== "" && !!field
        ).map(product => {
          const productPresent = this.state.products.find(item => item.codprodotto===product.codprodotto)
          return {
            codprodotto: product.codprodotto,
            valoreContratto: !!productPresent ? productPresent.valoreContratto :product.valoreContratto,
            valoreCompetenza: !!productPresent ? productPresent.valoreCompetenza :product.valoreCompetenza,
            fatturazione: !!productPresent ? productPresent.fatturazione ==='25' ? '25' :"50" : product.fatturazione ==='25' ? '25' :"50",
          }}).filter(item => !item.codprodotto.includes('AD')),

      addon: this.state.selectedAddon.map(item => {
        return {
          codprodotto: item.codprodotto,
          valoreCompetenza: item.valoreCompetenza,
          valoreContratto: item.valoreContratto,
          fatturazione: item.fatturazione === '25' ? '25' : '50'
        }
      })//.concat(this.state.addon).map(item => item.codprodotto).filter((x, i, a) => a.indexOf(x) == i)
    };
    const legalAddress = this.state.legalAddressAddressField +", "+ this.state.legalAddressZipcodeField +", "+ this.state.legalAddressCityField +", "+ this.state.legalAddressProvinceField +", "+ this.state.legalAddressCountryField
    const operativeAddress = contractData.operativeAddressAddressField +", "+ contractData.operativeAddressZipcodeField +", "+ contractData.operativeAddressCityField +", "+  contractData.operativeAddressProvinceField +", "+ contractData.operativeAddressCountryField

    contractData = {
      ...contractData,
      legalAddress: legalAddress,
      operativeAddress: operativeAddress,
    }

    if (contractData.products.length === 0&&contractData.addon.length===0) {
      this.setState({ noProductsSelected: true });
    } else {
      await this.saveContract(contractData, isPriceQuotation);
    }
    if(!!forceRefresh){
      window.location.reload(false);
    } else {
      this.props.history.push('/ListaContratti')
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeContractProducts = (event) => {
    const product = this.state.contractProduct[event.target.name].find(product => product.codprodotto == event.target.value)
    this.setState({
      selectedProducts: { ...this.state.selectedProducts, [event.target.name]: product },
    });
    this.setState({errorDiscount:""})
  };

  renderSelect = (arrayProduct, stateField) => {
    if (!arrayProduct) {
      return null;
    } else {
      return (
        <select
          name={stateField}
          id={stateField}
          value={!!this.state.selectedProducts[stateField]?this.state.selectedProducts[stateField].codprodotto : ''}
          onChange={this.handleChangeContractProducts}
          style={{
            width: "100%",
            border: "1px solid #ccc",
            height: 38,
            borderRadius: isMobile ? 0 : 4,
          }}
        >
          <option value="">--- Seleziona ---</option>
          {arrayProduct.map((singleProduct) => (
            <option value={singleProduct.codprodotto}>{`${singleProduct.nomeProdotto} - ${
              singleProduct.valoreCompetenza ? "€ " + singleProduct.valoreCompetenza : ""
            }`}</option>
          ))}
        </select>
      );
    }
  };

  renderSelectName = (name) => {
    switch (name) {
      case "LI":
        return "Ecommerce";
      case "GS":
        return "Google Shopping";
      case "BL":
        return "Blog";
      case "NL":
        return "News Letter";
      case "FB":
        return "Facebook";
      case "BA":
        return "Banner";
      case "NX":
        return "Nexi";
      case "FE":
        return "Feedaty";
      case "WA":
        return "Whatsapp";
      case "FD":
        return "Farmadati";
      case "AP":
        return "App Mobile";
      case "WS":
        return "Sito Web";
      case "DP":
        return "Digital Pharma";
      case "SM":
        return "Smart Pharma";
      case "CO":
        return "Corredo";
      case "GA":
        return "Google Analytics";
      case "IU":
        return "Iubenda";
      case "SA":
        return "Satispay";
      case "TP":
        return "Trovaprezzi";
      case "CA":
        return "Facebook Feed";
      case "CR":
        return "CRM Pazienti";
      case "PA":
        return "Pacchetti";
      case "SO":
        return "Social";
      case "VE":
        return "Vendite estero";

      default:
        return name;
    }
  };

  renderSelects = () => {
    const selects = Object.entries(this.state.contractProduct);
    const width = isMobile ? "100%" : "45%";
    return selects.map((arrSelect) => {
      return (
        <div
          className="form-group"
          style={{ flex: 1, flexBasis: width, maxWidth: width, marginRight: isMobile ? 0 : "5%" }}
        >
          <label>{this.renderSelectName(arrSelect[0])}</label>
          <div className="label-element">{this.renderSelect(arrSelect[1], arrSelect[0])}</div>
        </div>
      );
    });
  };

  isDisabled = () => {
    const isBeingCreated = !!this.state.creatingContract;

    const areFieldsCompiled = formFields.every(({ label, isRequired }) =>
      formFields.isRequired ?  true : ((!!this.state[label] && this.state[label] !== "") || !isRequired)
    );
    const areAddressFieldsCompiled = addressField.filter(item => item.type==="legalAddress").every(({ label, isRequired }) =>
      formFields.isRequired ?  true : ((!!this.state[label] && this.state[label] !== "") || !isRequired)
    );
    let operativeAddressEnabledCompiled
    if(!this.state.isSameAddress){
      operativeAddressEnabledCompiled = addressField
        .filter(item => item.type==="operativeAddress")
        .every(({ label, isRequired }) =>
        formFields.isRequired ?  true : ((!!this.state[label] && this.state[label] !== "") || !isRequired)
      );
    } else {
      operativeAddressEnabledCompiled = true
    }


    const isAnyProductSelected =
      Object.values(this.state.selectedProducts).filter((field) => field !== "" && !!field).length > 0 || this.state.selectedAddon.length > 0;
    return isBeingCreated || !areFieldsCompiled || !isAnyProductSelected || !areAddressFieldsCompiled || !operativeAddressEnabledCompiled;
  };

  renderFieldName = (name) => {
    switch (name) {
      case "companyName":
        return "Ragione Sociale";
      case "vatCode":
        return "P.IVA";
      case "emails":
        return "Email";
      case "address":
        return "Indirizzo";
      case "zipcode":
        return "CAP";
      case "city":
        return "Città";
      case "province":
        return "Provincia";
      case "country":
        return "Stato";
      case "legalRepresentative":
        return "Rappresentante Legale";
      case "companyType":
        return "Tipologia Società";
      case "legalAddress":
        return "Indirizzo sede legale";
      case "operativeAddress":
        return "Indirizzo sede operativa";
      case "taxCode":
        return "Codice Fiscale";
      case "phone":
        return "Telefono Società";
      case "cell":
        return "Cellulare";
      case "pec":
        return "Pec Mail";
      case "iban":
        return "IBAN";
      case "sdiCode":
        return "SDI (Codice Univoco)";
      case "operationalContact":
        return "Referente Operativo (per la farmacia)";

      case "isPubblicaAmministrazione":
        return "Pubblica amministrazione";
      case "cig":
        return "CIG";

      case "legalAddressAddressField":
        return "Via";
      case "legalAddressZipcodeField":
        return "Cap";
      case "legalAddressCityField":
        return "Città";
      case "legalAddressProvinceField":
        return "Provincia";
      case "legalAddressCountryField":
        return "Nazione";

      case "operativeAddressAddressField":
        return "Via";
        case "operativeAddressZipcodeField":
          return "Cap";
      case "operativeAddressCityField":
        return "Città";
      case "operativeAddressProvinceField":
        return "Provincia";
      case "operativeAddressCountryField":
        return "Nazione";

      default:
        return name;
    }
  };

  renderTextInputs = () => {
    const width = isMobile ? "100%" : "45%";
    return formFields.map(({ label, isRequired, searchable, type }) => (
      <div
        className="form-group"
        style={{ flex: 1, flexBasis: width, maxWidth: width, marginRight: isMobile ? 0 : "5%" }}
      >
        <label htmlFor={label}>{`${this.renderFieldName(label)}${isRequired ? "*" : ""}`}</label>
        {type==="select"
          ?
          <div style={{width: "100%", display: "flex", alignItems: "center"}}>
            <select
              name={label}
              id={label}
              value={this.state[label]}
              disabled={!(this.state.isNew||this.state.status==="CREATED")}
              onChange={this.handleChange }
              style={{
                width: "100%",
                border: "1px solid #ccc",
                height: 38,
                borderRadius: isMobile ? 0 : 4,
              }}
            >
              <option value="">--- Seleziona ---</option>
              {companyType.map((type) => (
                <option value={type}>{`${type}`}</option>
              ))}
            </select>
          </div>
          :<div>
          <div style={{width: "100%", display: "flex", alignItems: "center"}}>
          <input
            disabled={!(this.state.isNew||this.state.status==="CREATED")}
            id={label}
            name={label}
            type={"text"}
            value={this.state[label]}
            className="form-control"
            onChange={this.handleChange}
            placeholder={
              searchable
                ? `Inserisci ${this.renderFieldName(label)}`
                : `Inserisci ${this.renderFieldName(label).toLowerCase()}`
            }
          />
          {/*{searchable && this.state.isNew && (*/}
          {/*  <div style={{ marginLeft: isMobile ? 8 : 16 }}>*/}
          {/*    <button*/}
          {/*      type="submit"*/}
          {/*      className="btn btn-primary"*/}
          {/*      style={{*/}
          {/*        backgroundColor: "#39fbc7",*/}
          {/*        border: "none",*/}
          {/*        display: "flex",*/}
          {/*        alignItems: "center",*/}
          {/*        justifyContent: "center",*/}
          {/*        borderRadius: isMobile ? 0 : 8,*/}
          {/*      }}*/}
          {/*      onClick={this.getDataByVat}*/}
          {/*      disabled={this.state.loading}*/}
          {/*    >*/}
          {/*      <CgSearch style={{ fontSize: 20 }} />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {this.state.errorString !== "" && searchable && (
          <div style={{ color: "red" }}>{this.state.errorString}</div>
        )}
      </div>}
      </div>
    ));
  };

  handleSendContract = async () => {
    this.setState({ sendingContract: true });
    const contractId = this.props.match.params.id;
    const { method, endpoint, headers } = api.sendContract(contractId);
    try {
      await axios({
        url: endpoint,
        method,
        headers,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    this.setState({ sendingContract: false });
  };

  remind = async () => {
    this.setState({ reminding: true });
    const contractId = this.props.match.params.id;
    const { method, endpoint, headers } = api.remindContract(contractId);
    try {
      await axios({
        url: endpoint,
        method,
        headers,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    this.setState({ reminding: false });
  };

  renderProducts = () => {
    const productFieldsToShow = [
      "codprodotto",
      // "valoreCompetenza",
      // "competenza",
      "fatturazione",
      "durataContratto",
      // "numeroRate",
      "valoreListino",
      "offertaCliente",
      "nomeProdotto",
      "descrizione",
    ];
    if (isMobile) {
      return this.state.products
        .sort((a, b) => (a.codprodotto > b.codprodotto ? 1 : -1))
        .map((product) => (
          <MobileProduct product={product} productFieldsToShow={productFieldsToShow} contractProduct={this.state.contractProduct} handleChangeValoreContratto={this.handleChangeValoreContratto} onBlurCheckMaxDiscount={this.onBlurCheckMaxDiscount} updateBillingPolicy={this.updateBillingPolicy}/>
        ));
    }
    return (
      <table>
        <thead>
          <tr className="header-table">
            {productFieldsToShow.map((key) => (
              <th id={key} className={key}>
                <div style={{ textTransform: "capitalize" }}>
                  {key.split(/(?=[A-Z])/).join(" ")}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontSize: 16 }}>
          {this.state.products.concat(this.state.addon)
            .sort((a, b) => (a.codprodotto > b.codprodotto ? 1 : -1))
            .map((product) => {
              return (
                <tr>
                  {productFieldsToShow.map((key) => {
                    if(key==='valoreListino') {
                      const label = product.codprodotto.slice(0,2)
                      const productOriginal = this.state.contractProduct[label]?.find(item => item.codprodotto === product.codprodotto)
                      return <th>
                        {!!productOriginal
                          ? productOriginal.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          : !!product['valoreContratto']
                            ? product['valoreContratto'].toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : ""
                        }
                      </th> //{productOriginal.valoreContratto}
                    } else if(key === 'offertaCliente') {
                      return <th>{!!product['valoreContratto'] ?`${key.includes("valore") ? "€ " : ""}${
                        product['valoreContratto'] ? product['valoreContratto'].toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
                      }` :''}</th>
                    } else if(key==='fatturazione'){
                      if(product['fatturazione']!=='50'&&product['fatturazione']!=='25'){
                        return <th>{product['fatturazione']}</th>
                      } else {
                        return <th>
                          <select name="fatturazione" id="fatturazione" defaultValue={product.fatturazione} disabled={this.state.status==="SENT"||this.state.status==="SIGNED"} onChange={event => this.updateBillingPolicy(event, product)}>
                            <option value="">---seleziona la fatturazione---</option>
                            <option value="50">Acconto: 50%, 30g:10%,60g:10%,90g:10%,120g:10%,180g:10%</option>
                            <option value="25">Acconto: 25%, 30g:15%,60g:15%,90g:15%,120g:15%,180g:15%</option>
                            <option value="25">Acconto: 25%, 75% ogni mese per 11 mesi</option>
                          </select>
                        </th>
                      }
                    } else if(key==='durataContratto'){
                      if(product[key]===1){
                        return <th>Una tantum</th>
                      } else {
                        return <th>{!!product[key] ?`${key.includes("valore") ? "€ " : ""}${
                          product[key] ? product[key] : ""
                        }` :''}</th>
                      }
                    } else {
                      return <th>{!!product[key] ?`${key.includes("valore") ? "€ " : ""}${
                        product[key] ? product[key] : ""
                      }` :''}</th>
                    }
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  renderProductsCreation = () => {
    const productFieldsToShow = [
      "codprodotto",
      // "valoreCompetenza",
      // "competenza",
      "fatturazione",
      "durataContratto",
      // "numeroRate",
      "valoreListino",
      "offertaCliente",
      "nomeProdotto",
      "descrizione",
    ];
    let selectedProductArray = Object.keys(this.state.selectedProducts).filter(label => {
      return !!this.state.selectedProducts[label]
    }).map(label => {
      return this.state.selectedProducts[label]
    })

    if(this.state.status==="CREATED") {
      selectedProductArray = this.state.products.filter(item => item.codprodotto.slice(0,2) !== 'AD')
      const productSelectedWithoutLabel = Object.keys(this.state.selectedProducts).filter(label => {
        return !!this.state.selectedProducts[label]
      })
        .map(label => {
        return this.state.selectedProducts[label]
      })

      productSelectedWithoutLabel
        // .filter(item => item.codprodotto.slice(0,2) !== 'AD')
        .map(item => {
        const foundProduct = selectedProductArray.find(selItem => selItem.codprodotto === item.codprodotto)
        const foundAddon = this.state.addonContract.find(selItem => selItem.codprodotto === item.codprodotto)
        if(!(foundProduct||foundAddon)) {
          selectedProductArray.push(item)
        }
      })
    }
    if (isMobile) {
      return selectedProductArray.concat(this.state.selectedAddon)
        .sort((a, b) => (a.codprodotto > b.codprodotto ? 1 : -1))
        .map((product) => (
          <MobileProduct product={product} productFieldsToShow={productFieldsToShow} contractProduct={this.state.contractProduct} handleChangeValoreContratto={this.handleChangeValoreContratto} onBlurCheckMaxDiscount={this.onBlurCheckMaxDiscount} updateBillingPolicy={this.updateBillingPolicy}/>
        ));
    }
    return (
      <table>
        <thead>
        <tr className="header-table">
          {productFieldsToShow.map((key) => (
            <th id={key} className={key}>
              <div style={{ textTransform: "capitalize" }}>
                {key.split(/(?=[A-Z])/).join(" ")}
              </div>
            </th>
          ))}
        </tr>
        </thead>
        <tbody style={{ fontSize: 16 }}>
        {selectedProductArray.concat(this.state.addon)
          .sort((a, b) => (a.codprodotto > b.codprodotto ? 1 : -1))
          .map((product) => {
            return (
              <tr>
                {productFieldsToShow.map((key) => {
                  if(key==='offertaCliente') {
                    if(product.vincolato === 'Primario'){
                      return <th><input id={key}
                                        name={key}
                                        value={product['valoreContratto']}
                                        onChange={event => this.handleChangeValoreContratto(event, product)}
                                        onBlur={event => this.onBlurCheckMaxDiscount(event, product)}
                      /></th>
                    } else {
                      return <th>{`${key.includes("valore") ? "€ " : ""}${
                        product['valoreContratto'] ? product['valoreContratto'].toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
                      }`}</th>
                    }
                } else if(key==='valoreListino') {
                  const label = product.codprodotto.slice(0,2)
                  const productOriginal = this.state.contractProduct[label]?.find(item => item.codprodotto === product.codprodotto)
                  return <th>
                    {!!productOriginal?.valoreContratto
                      ? productOriginal?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : product?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </th>
                } else if(key==='fatturazione'){
                  return <th>
                    <select name="fatturazione" id="fatturazione" defaultValue={product.fatturazione} disabled={this.state.status==="SENT"||this.state.status==="SIGNED"} onChange={event => this.updateBillingPolicy(event, product)}>
                      <option value="">---seleziona la fatturazione---</option>
                      <option value="50">Acconto: 50%, 30g:10%,60g:10%,90g:10%,120g:10%,180g:10%</option>
                      <option value="25">Acconto: 25%, 30g:15%,60g:15%,90g:15%,120g:15%,180g:15%</option>
                      <option value="25">Acconto: 25%, 75% ogni mese per 11 mesi</option>
                    </select>
                  </th>
                } else {
                  return <th>{`${key.includes("valore") ? "€ " : ""}${
                    product[key] ? product[key] : ""
                  }`}</th>
                }
                })}
              </tr>
            );
          })}
        {this.state.selectedAddon.map((product) => {
          return (
            <tr>
              {productFieldsToShow.map((key) => {
                if(key==='offertaCliente') {
                  if (product.vincolato === 'Primario') {
                    return <th><input id={key}
                                      name={key}
                                      value={product['valoreContratto']}
                                      onChange={event => this.handleChangeValoreContrattoAddon(event, product)}
                                      onBlur={event => this.onBlurCheckMaxDiscountAddon(event, product)}
                    /></th>
                  } else {
                    return <th>{`${key.includes("valore") ? "€ " : ""}${
                      product['valoreContratto'] ? product['valoreContratto'].toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
                    }`}</th>
                  }
                }
                if(key==='valoreListino') {
                  const productOriginal = this.state.addonContract?.find(item => item.codprodotto === product.codprodotto)
                  return <th>
                    {!!productOriginal?.valoreContratto
                      ? productOriginal?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : product?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </th>
                }

                if(key==='durataContratto'){
                  if(product[key]===1){
                    return <th>Una tantum</th>
                  } else {
                    return <th>{!!product[key] ?`${key.includes("valore") ? "€ " : ""}${
                      product[key] ? product[key] : ""
                    }` :''}</th>
                  }
                }

                if(key==='fatturazione'){
                  return <th>
                    <select
                      name="fatturazione"
                      id="fatturazione"
                      defaultValue={product.fatturazione}
                      disabled={this.state.status==="SENT"||this.state.status==="SIGNED"}
                      onChange={event => this.updateBillingPolicyAddon(event, product)}>
                      <option value="">---seleziona la fatturazione---</option>
                      <option value="50">Acconto: 50%, 30g:10%,60g:10%,90g:10%,120g:10%,180g:10%</option>
                      <option value="25">Acconto: 25%, 30g:15%,60g:15%,90g:15%,120g:15%,180g:15%</option>
                      <option value="25">Acconto: 25%, 75% ogni mese per 11 mesi</option>
                    </select>
                  </th>
                }
                return <th>{`${key.includes("valore") ? "€ " : ""}${
                  product[key] ? product[key] : ""
                }`}</th>
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
    );
  };

  updateBillingPolicy = (event, product) => {
    const label = product.codprodotto.slice(0,2)
    if(this.state.status === 'CREATED'){
      this.setState({products: this.state.products.map(productPresent => {
          if(productPresent.codprodotto===product.codprodotto){
            return {
              ...productPresent,
              fatturazione: event.target.value,
            }
          } else {
            return productPresent
          }
        })})

    } else {
      this.setState({selectedProducts: {
          ...this.state.selectedProducts,
          [label]: {
            ...this.state.selectedProducts[label],
            fatturazione: event.target.value,
          }
        }})
    }
  }

  updateBillingPolicyAddon = (event, product) => {
    this.setState({
      selectedAddon: this.state.selectedAddon.map(item => {
        if(item.codprodotto === product.codprodotto){
          return {
            ...item,
            fatturazione: event.target.value,
          }
        }
        return item
      })
    })

  }

  onBlurCheckMaxDiscount = (event, product) => {
    const newValoreContratto = event.target.value === "" ? 0 : parseInt(event.target.value)
    let minValue
    if(!this.checkMaxDiscount(product, newValoreContratto)){
      const realProductValue = this.state.contractProduct[product.codprodotto.slice(0,2)].find(item => item.codprodotto === product.codprodotto).valoreContratto
      if(!product.maxDiscountPercentage) {
        // minValue = product.valoreContratto
        minValue = realProductValue
        this.setState({errorDiscount: 'Il valore inserito di '+product.codprodotto+' deve essere uguale a: '+minValue})
      } else if(realProductValue*(100-product.maxDiscountPercentage)/100 > newValoreContratto){
        minValue = realProductValue*(100-product.maxDiscountPercentage)/100
        this.setState({errorDiscount: 'Il valore inserito di '+product.codprodotto+' deve essere maggiore di: '+minValue})
      }
      this.handleChangeValoreContratto({
        ...event,
        target: {
          ...event.target,
          value: minValue,
        }
      }, product)
    }
  }

  onBlurCheckMaxDiscountAddon = (event, product) => {
    const newValoreContratto = event.target.value === "" ? 0 : parseInt(event.target.value)
    let minValue
    if(!this.checkMaxDiscountAddon(product, newValoreContratto)){
      const realProductValue = this.state.addonContract.find(item => item.codprodotto === product.codprodotto).valoreContratto
      if(!product.maxDiscountPercentage) {
        minValue = realProductValue
        this.setState({errorDiscount: 'Il valore inserito di '+product.codprodotto+' deve essere uguale a: '+minValue})
      } else if(realProductValue*(100-product.maxDiscountPercentage)/100 > newValoreContratto){
        minValue = realProductValue*(100-product.maxDiscountPercentage)/100
        this.setState({errorDiscount: 'Il valore inserito di '+product.codprodotto+' deve essere maggiore di: '+minValue})
      }
      this.handleChangeValoreContrattoAddon({
        ...event,
        target: {
          ...event.target,
          value: minValue,
        }
      }, product)
    }
  }

  handleChangeValoreContratto = (event, product) => {
    const newValoreContratto = event.target.value === "" ? 0 : parseFloat(event.target.value)

    if(newValoreContratto === product.valoreContratto){
      this.updateSelectedProduct(product, newValoreContratto, product.valoreCompetenza)
    }
    let valoreCompetenza = product.valoreCompetenza
    if(product.competenza === 'Una Tantum') {
      valoreCompetenza = newValoreContratto
      this.updateSelectedProduct(product, newValoreContratto, valoreCompetenza)
      return
    }
    if(product.competenza === "Annuale") {
      valoreCompetenza = newValoreContratto
      this.updateSelectedProduct(product, newValoreContratto, valoreCompetenza)
      return
    }
    if(product.competenza === 'Giornaliera') {
      const numeroAnni = parseInt(product.numeroRate/12)
      const numeroGiorniInUnAnno = 365
      valoreCompetenza = newValoreContratto/(numeroGiorniInUnAnno*numeroAnni)
      this.updateSelectedProduct(product, newValoreContratto, valoreCompetenza)
      return
    }

    if(product.competenza === 'Mensile') {
      valoreCompetenza = newValoreContratto/product.numeroRate
      this.updateSelectedProduct(product, newValoreContratto, valoreCompetenza)
      return
    }
  }

  handleChangeValoreContrattoAddon = (event, product) => {
    const newValoreContratto = event.target.value === "" ? 0 : parseFloat(event.target.value)
    this.updateSelectedAddon(product, newValoreContratto)
  }

  updateSelectedAddon = (product, nuovoValoreContratto) => {
    this.setState({selectedAddon: this.state.selectedAddon.map(item => {
      if(item.codprodotto === product.codprodotto){
        return {
          ...item,
          valoreContratto: nuovoValoreContratto,
        }
      }
      return item
      })})


  }

  updateSelectedProduct = (product, nuovoValoreContratto, nuovoValoreCompetenza) => {
    const label = product.codprodotto.slice(0,2)
    if(this.state.status === 'CREATED'){
      this.setState({products: this.state.products.map(productPresent => {
            if(productPresent.codprodotto===product.codprodotto){
              return {
                ...productPresent,
                valoreContratto: nuovoValoreContratto,
                valoreCompetenza: nuovoValoreCompetenza,
              }
            } else {
              return productPresent
            }
          })})

    } else {
      this.setState({selectedProducts: {
          ...this.state.selectedProducts,
          [label]: {
            ...this.state.selectedProducts[label],
            valoreContratto: nuovoValoreContratto,
            valoreCompetenza: nuovoValoreCompetenza,
          }
        }})
    }

  }

  checkMaxDiscount = (product, nuovoValoreContratto) => {
    // Get old product value
    const label = product.codprodotto.slice(0,2)
    const realProductValue = this.state.contractProduct[label].find(item => item.codprodotto === product.codprodotto).valoreContratto

    const maxDiscountPercentage = product.maxDiscountPercentage
    if(!maxDiscountPercentage) {
      return false
    }
    if(realProductValue*(100-maxDiscountPercentage)/100 > nuovoValoreContratto){
      return false
    }
    return true
  }

  checkMaxDiscountAddon = (product, nuovoValoreContratto) => {
    // Get old product value
    const label = product.codprodotto.slice(0,2)
    const realProductValue = this.state.addonContract.find(item => item.codprodotto === product.codprodotto).valoreContratto

    const maxDiscountPercentage = product.maxDiscountPercentage
    if(!maxDiscountPercentage) {
      return false
    }
    if(realProductValue*(100-maxDiscountPercentage)/100 > nuovoValoreContratto){
      return false
    }
    return true
  }

  getActionButtonData = () => {
    switch (this.state.status) {
      case "SENT":
        return {
          onClick: this.remind,
          loading: this.state.reminding,
          label: "Manda remind",
        };
      case "CREATED":
        return {
          onClick: () => this.handleSubmit(false),
          loading: this.state.creatingContract,
          label: "Aggiorna ed invia per la firma",
        };
      default:
        return null;
    }
  };

  renderActionButton = () => {
    const data = this.getActionButtonData();
    return (
      data && <ActionButton onClick={data.onClick} loading={data.loading} label={data.label} />
    );
  };

  getDataByVat = async () => {
    this.setState({ loading: true, errorString: "" });

    // se non hanno inserito una partita iva mostro errore e blocco
    if (!this.state.vatCode) {
      this.setState({ errorString: "Inserisci una P.IVA", loading: false });
      return;
    }

    const res = await getCompanyDataFromVat(this.state.vatCode);
    const data = res?.data;

    if (data) {
      if (res?.meta?.count === 0) {
        this.setState({ errorString: "Nessun risultato trovato" });
      } else {
        this.setState({
          companyName: data.companyName,
          legalRepresentative: data.legalRepresentative,
          legalAddress: data.legalAddress,
          operativeAddress: data.operativeAddress,
          taxCode: data.taxCode,
          vatCode: data.vatCode,
          phone: data.phone,
          cell: data.cell,
          emails: data.emails,
          pec: data.pec,
          sdiCode: data.sdiCode,
          operationalContact: data.operationalContact,
        });
      }
    }

    this.setState({ loading: false });
  };

  renderAddressInputs = (type) => {
    const width = isMobile ? "100%" : "45%";
    return addressField.filter(address => address.type===type).map(({ label, isRequired, searchable, type }) => (
      <div
        className="form-group"
        style={{ flex: 1, flexBasis: width, maxWidth: width, marginRight: isMobile ? 0 : "5%" }}
      >
        {(type === "legalAddress"||(!this.state.isSameAddress && type === "operativeAddress")) &&
        <div>
          <label htmlFor={label}>{`${this.renderFieldName(label)}${isRequired ? "*" : ""}`}</label>
          {type === "select"
            ?
            <div style={{width: "100%", display: "flex", alignItems: "center"}}>
              <select
                name={label}
                id={label}
                disabled={!(this.state.isNew || this.state.status === "CREATED")}
                defaultValue={this.state.companyType}
                value={this.state.companyType}

                onChange={this.handleChange}
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  height: 38,
                  borderRadius: isMobile ? 0 : 4,
                }}
              >
                <option value="">--- Seleziona ---</option>
                {companyType.map((type) => (
                  <option value={type}>{`${type}`}</option>
                ))}
              </select>
            </div>
            : <div>
              <div style={{width: "100%", display: "flex", alignItems: "center"}}>
                <input
                  disabled={!(this.state.isNew || this.state.status === "CREATED")}
                  id={label}
                  name={label}
                  type={"text"}
                  value={this.state[label]}
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder={
                    searchable
                      ? `Cerca una ${this.renderFieldName(label)}`
                      : `Inserisci ${this.renderFieldName(label).toLowerCase()}`
                  }
                />
                {searchable && this.state.isNew && (
                  <div style={{marginLeft: isMobile ? 8 : 16}}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#39fbc7",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: isMobile ? 0 : 8,
                      }}
                      onClick={this.getDataByVat}
                      disabled={this.state.loading}
                    >
                      <CgSearch style={{fontSize: 20}}/>
                    </button>
                  </div>
                )}
              </div>
              {this.state.errorString !== "" && searchable && (
                <div style={{color: "red"}}>{this.state.errorString}</div>
              )}
            </div>}
        </div>
        }
      </div>
    ));
  }

  renderPubblicaAmministrazione = (element) => {
    const width = isMobile ? "100%" : "45%";
    if(element.type==="checkbox") {
      return (
        <div
          className="form-group"
          style={{flex: 1, flexBasis: width, maxWidth: width, marginRight: isMobile ? 0 : "5%"}}
        >
            <div style={{width: "100%", display: "flex", alignItems: "center", marginTop:24}}>
              <label style={{margin: 0}}>
                {this.renderFieldName(element.label)}
                <input
                    disabled={!(this.state.isNew||this.state.status==="CREATED")}
                  type="checkbox"
                  checked={this.state.isPubblicaAmministrazione}
                  onChange={() => this.setState({isPubblicaAmministrazione: !this.state.isPubblicaAmministrazione})}
                  style={{marginLeft: 16}}
                />
              </label>
            </div>
        </div>

      )
    } else {
      return (
        <div
          className="form-group"
          style={{flex: 1, flexBasis: width, maxWidth: width, marginRight: isMobile ? 0 : "5%"}}
        >

          {this.state.isPubblicaAmministrazione && <Fragment><label
            htmlFor={element.label}>{`${this.renderFieldName(element.label)}${element.isRequired ? "*" : ""}`}</label>
            <input
            disabled={!(this.state.isNew || this.state.status === "CREATED")}
            id={element.label}
            name={element.label}
            type={"text"}
            value={this.state[element.label]}
            className="form-control"
            onChange={this.handleChange}
            placeholder={
            element.searchable
            ? `Cerca una ${this.renderFieldName(element.label)}`
            : `Inserisci ${this.renderFieldName(element.label).toLowerCase()}`
          }
            /></Fragment>}
        </div>
      )
    }
  }

  deleteContract = async () => {
    this.setState({ deletingContract: true });
    const contractId = this.props.match.params.id;
    const { method, endpoint, headers } = api.deleteContract(contractId);
    try {
      await axios({
        url: endpoint,
        method,
        headers,
      });
      this.setState({ deletingContract: false });
      this.props.history.push("/ListaContratti")
    } catch (err) {
      console.log(err);
      this.setState({ deletingContract: false, errorDelete:true });
    }

  }

  renderTotals = () => {
    const products = this.state.products.filter(item => !item.codprodotto.includes('AD')).concat(this.state.selectedAddon)


    const totalContract = products.reduce((acc, curr) => acc+curr.valoreContratto, 0)


    const totalFirstYear = products.reduce((acc, curr) => {
      if(curr.durataContratto<12){
        return acc + parseFloat((curr.valoreContratto) || 0)
      } else {
        if(curr.ambito.toLowerCase() === 'servizio') {
          return acc + parseFloat((curr.valoreContratto/curr.durataContratto*12) || 0);
        }
        if(curr.ambito.toLowerCase()==='prodotto'){
          if(!!this.state.productPaymentExtended){
            return acc + parseFloat((curr.valoreContratto/curr.durataContratto*12) || 0);
          } else {
            return acc + parseFloat((curr.valoreContratto) || 0)
          }
        }
      }
    }, 0)

    // Calcolo l'acconto da versare facendo distinzione tra prodotti e servizi
    let acconto = products.reduce((acc, curr) => {
      if(curr.ambito.toLowerCase() === "prodotto"){
        // Controllo se ha abilitato il pagamento dei prodotti su più anni
        if(!!this.state.productPaymentExtended){
          return acc + curr.valoreContratto/curr.durataContratto*12*parseInt(curr.fatturazione)/100
        } else {
          return acc + curr.valoreContratto*parseInt(curr.fatturazione)/100
        }
      } else {
        if(curr.durataContratto===1){
          return acc + curr.valoreContratto*parseInt(curr.fatturazione)/100
        }
        return acc + curr.valoreContratto/curr.durataContratto*12*parseInt(curr.fatturazione)/100
      }
    }, 0)

    // Calcolo il totale delle rate successive
    let altreRate = products.reduce((acc, curr) => {
      // Controllo che sia un prodotto
      if(curr.ambito.toLowerCase() === "prodotto"){
        // Controllo se ha abilitato il pagamento dei prodotti su più anni
        if(!!this.state.productPaymentExtended){
          return acc + curr.valoreContratto*(100-parseInt(curr.fatturazione))/curr.durataContratto*12/100/5
        } else {
          return acc + curr.valoreContratto*(100-parseInt(curr.fatturazione))/100/5
        }
      } else {
        //Altrimenti è un servizio
        if(curr.durataContratto===1){
          return acc + curr.valoreContratto*(100-parseInt(curr.fatturazione))/100/5
        }
        return acc + curr.valoreContratto/curr.durataContratto*12*(100-parseInt(curr.fatturazione))/100/5
      }
    }, 0)

    return <div>
      <div style={{display:"flex"}}>
        <div style={{fontWeight:"bold", fontSize:16}}>Totale contratto: </div>
        <div>{totalContract.toFixed(2).toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}€</div>
      </div>
      <div style={{display:"flex"}}>
        <div style={{fontWeight:"bold", fontSize:16}}>Totale primo anno: </div>
        <div>{totalFirstYear.toFixed(2).toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}€</div>
      </div>
      <div style={{display:"flex", marginTop:16}}>
        <div style={{fontWeight:"bold", fontSize:16}}>Pagamenti</div>
      </div>
      <div style={{display:"flex"}}>
        <div style={{fontWeight:"bold", fontSize:16}}>Acconto: </div>
        <div>{acconto.toFixed(2).toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}€</div>
      </div>
      <div style={{display:"flex"}}>
        <div style={{fontWeight:"bold", fontSize:16}}>Rate successive: </div>
        <div>{altreRate.toFixed(2).toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") || 0}€</div>
      </div>
    </div>
  }

  render() {
    return (
      <div className="nuovo-contratto">
        <div
          className="page-header"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "flex-start" : "space-between",
            alignItems: isMobile ? "flex-start" : "center",
            gap: isMobile ? 8 : 0,
          }}
        >
          {!this.state.isNew ? "Contratto n°" + this.props.match.params.id : "Nuovo contratto"}
          {(localStorage.getItem("scope")==="superadmin"&&this.state.status!=="SIGNED") &&
            <div style={{display:"flex", marginRight:"5%"}}>
              {!!this.state.errorDelete&&<div style={{color:"red", fontSize:16, marginRight:16, display:"flex", alignItems:"center"}}>Errore nella cancellazione del contratto</div>}
              <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    border: "1px solid red",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: isMobile ? 0 : 8,
                  }}
                  onClick={this.deleteContract}
                >
                  {this.state.deletingContract ? <Loader /> : "Elimina contratto"}
                </button>
              </div>}
        </div>
        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>{this.renderTextInputs()}</div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {pubblicaAmministrazione.map(field => {
            return(
              this.renderPubblicaAmministrazione(field)
            )
          })}
        </div>
        {addressType.map(addressType => {
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "flex-start" : "space-between",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: isMobile ? 8 : 0,
                  fontSize: 18,
                  color: "black",
                  fontWeight: "bold",
                  marginTop:isMobile ? 16 : 24,
                }}
              >
                {this.renderFieldName(addressType.label)}
              </div>
              <div >
                {addressType.label==="operativeAddress" && (
                  <div>
                    <label htmlFor="isSameAddress" >
                      <input type="checkbox" id="isSameAddress" name="isSameAddress" checked={this.state.isSameAddress} style={{marginRight:12}} onClick={() => this.setState({isSameAddress:!this.state.isSameAddress})}/>
                      Uguale all'indirizzo legale
                    </label>
                  </div>
                )}
                <div style={{display: "flex", flexWrap: "wrap"}}>{this.renderAddressInputs(addressType.label)}</div>
              </div>
            </div>
          )
        })}
        {(this.state.isNew||this.state.status==="CREATED") ? (
            <div style={{ marginTop: 40, display: "flex", flexWrap: "wrap" }}>
              {this.renderSelects()}
              <div
                className="form-group"
                style={{ flex: 1, flexBasis: isMobile ? "100%" : "45%", maxWidth: isMobile ? "100%" : "45%", marginRight: isMobile ? 0 : "5%" }}
              >
                <label>Potenziamento</label>
                <div>
                  <MultiSelect
                    options={this.state.addonContract}
                    value={this.state.selectedAddon || []}
                    onChange={(data) => this.setState({selectedAddon: data})}
                    labelledBy="Select"
                    disableSearch={true}
                    hasSelectAll={false}
                  />
                </div>
              </div>

            </div>
          )
          :(
          <div>
            <div style={{ marginTop: 40, cursor: "none!important" }}>
              <div style={{ marginBottom: 16 }}>
                <b>Prodotti</b>
              </div>
              {this.renderProducts()}
            </div>

          </div>
        )}
        {(this.state.isNew||this.state.status==="CREATED") && <div>
          <div style={{ marginTop: 40, cursor: "none!important" }}>
            <div style={{ marginBottom: 16 }}>
              <b>Prodotti</b>
            </div>
            {this.renderProductsCreation()}
          </div>

        </div>}

        <div style={{marginTop:20}}>
          <div style={{fontWeight:"bold", fontSize:16, marginBottom:16}}>Note</div>
          <textarea
            name="note"
            id="note"
            rows="5"
            style={{width:'100%', resize: "none"}}
            placeholder={'Note...'}
            onChange={this.handleChange}
            disabled={!this.state.isNew&&!(this.state.status==='CREATED')}
            value={this.state.note||''}
          />
        </div>
        {!this.state.isNew && <div style={{marginTop: "40px"}}>
          <div>
            <b>Contratti</b>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 16,
              marginTop: 16,
            }}
          >
            {this.state.filesName.map((elem) => (
              <FileButton elem={elem}/>
            ))}
          </div>
        </div>}

        {/*{!this.state.isNew && <div>*/}
        {/*  <div style={{ marginTop: 40, cursor: "none!important" }}>*/}
        {/*    <div style={{ marginBottom: 16 }}>*/}
        {/*      <b>Prodotti</b>*/}
        {/*    </div>*/}
        {/*    {this.renderProducts()}*/}
        {/*  </div>*/}

        {/*</div>}*/}
        <div>
          <label htmlFor="productPaymentExtended" >
            <input type="checkbox" id="productPaymentExtended" name="productPaymentExtended" checked={this.state.productPaymentExtended} style={{marginRight:12}} onClick={() => this.setState({productPaymentExtended:!this.state.productPaymentExtended})}/>
            Estendi pagamento prodotti alla durata del contratto
          </label>
        </div>

        {!this.state.isNew && <div>
          <div style={{ marginTop: 40, cursor: "none!important" }}>
            {/*<div style={{ marginBottom: 16 }}>*/}
            {/*  <b>Prodotti2</b>*/}
            {/*</div>*/}
            {this.renderTotals()}
          </div>

        </div>}

        {/*{!this.state.isNew && <div style={{marginTop: "40px"}}>*/}
        {/*  <div>*/}
        {/*    <b>Status esportazione</b>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      display: "flex",*/}
        {/*      flexWrap: "wrap",*/}
        {/*      gap: 16,*/}
        {/*      marginTop: 16,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {renderExportingStatus(this.state.exportingStatus)}*/}
        {/*  </div>*/}
        {/*</div>}*/}

        <div style={this.state.isNew ? { marginTop: "40px" } : { marginTop: "22px" }}>
          {this.state.error && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "16px" }}>
              Verificare i campi compilati
            </div>
          )}
          {this.state.errorConcessionario && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "16px" }}>
              La farmacia è già associata ad un altro concessionario
            </div>
          )}
          {this.state.noProductsSelected && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "16px" }}>
              Selezionare almeno un prodotto
            </div>
          )}
          {!!this.state.errorDiscount && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "16px" }}>
              {this.state.errorDiscount}
            </div>
          )}
          <div
            className={
              this.state.isNew
                ? "contratto-button nuovo-contratto-btn"
                : "contratto-button contratto-esistente"
            }
            style={{ marginTop: 64, flexDirection: isMobile ? "column" : "row", gap: 16 }}
          >
            <button
              type="button"
              className="btn btn-primary"
              style={{
                border: "1px solid #39fbc7",
                backgroundColor: "white",
                color: "#39fbc7",
                borderRadius: isMobile ? 0 : 8,
              }}
              onClick={this.goBack}
            >
              Torna indietro
            </button>
            {this.state.status==="CREATED"&&
              <button
                disabled={this.isDisabled()}
                onClick={() => this.handleSubmit(true, true)}
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#39fbc7",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: isMobile ? 0 : 8,
                }}
              >
                {this.state.creatingContract ? <Loader /> : "Aggiorna preventivo"}
              </button>
            }
            {!this.state.isNew && this.renderActionButton()}
            {this.state.isNew && (
              <button
                disabled={this.isDisabled()}
                onClick={() => this.handleSubmit(true)}
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#39fbc7",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: isMobile ? 0 : 8,
                }}
              >
                {this.state.creatingContract ? <Loader /> : "Crea preventivo"}
              </button>
            )}
            {this.state.isNew && (
              <button
                disabled={this.isDisabled()}
                onClick={() => this.handleSubmit()}
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#39fbc7",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: isMobile ? 0 : 8,
                }}
              >
                {this.state.creatingContract ? <Loader /> : "Crea e invia per la firma"}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const FileButton = ({ elem }) => {
  return (
    <a
      href={API_CONTRACT_URL + elem.url.trim()}
      target={"_blank"}
      style={{
        backgroundColor: "#eee",
        borderRadius: isMobile ? 0 : 8,
        padding: "8px 12px",
        textDecoration: "none",
        color: "black",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
      }} rel="noreferrer"
    >
      <FaFilePdf />
      {elem.name}
    </a>
  );
};

const ActionButton = (props) => {
  return (
    <button
      type="button"
      className="btn btn-primary"
      style={{
        backgroundColor: "#39fbc7",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: isMobile ? 0 : 8,
      }}
      onClick={props.onClick}
    >
      {props.loading ? <Loader /> : props.label}
    </button>
  );
};

const MobileProduct = ({ product, productFieldsToShow, status, contractProduct, handleChangeValoreContratto, onBlurCheckMaxDiscount, updateBillingPolicy }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        marginBottom: 16,

        padding: "8px 16px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",}}>
        <div
          onClick={toggleExpanded}
          style={{ fontWeight: "bold" }}
        >{`${product.codprodotto} ${!!product.valoreContratto && '- €'}${product.valoreContratto}`}</div>
        <div onClick={toggleExpanded}>{expanded ? <FaMinus /> : <FaPlus />}</div>
      </div>
      {expanded && (
        <div onClick={toggleExpanded} style={{ marginTop: 8, display:"flex", flexDirection: "column"}}>
          {/*{productFieldsToShow.map((key) => (*/}
          {/*  <div style={{ textTransform: "capitalize" }}>*/}
          {/*    <b>{key.split(/(?=[A-Z])/).join(" ")}:</b>{" "}*/}
          {/*    {`${key.includes("valore") ? "€ " : ""}${product[key] ? product[key] : ""}`}*/}
          {/*  </div>*/}
          {/*))}*/}
          {productFieldsToShow.map((key) => {
            if(key==='offertaCliente') {
              if(product.vincolato === 'Primario'){
                return <div style={{width:'100%', textTransform:'capitalize'}}>
                  <b>{key.split(/(?=[A-Z])/).join(" ")}:</b>{" "}
                  <input id={key}
                                  name={key}
                                  value={product['valoreContratto']}
                                  onChange={event => {
                                    event.stopPropagation()
                                    handleChangeValoreContratto(event, product)
                                  }}
                                  onBlur={event => onBlurCheckMaxDiscount(event, product)}
                         onClick={event => event.stopPropagation()}
                /></div>
              } else {
                return <div>{`${key.includes("valore") ? "€ " : ""}${
                  product['valoreContratto'] ? product['valoreContratto'].toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
                }`}</div>
              }
            } else if(key==='valoreListino') {
              const label = product.codprodotto.slice(0,2)
              const productOriginal = contractProduct[label]?.find(item => item.codprodotto === product.codprodotto)
              return <div><b>Valore contratto: </b>
                {!!productOriginal?.valoreContratto
                  ? productOriginal?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : product?.valoreContratto.toString().replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </div>
            } else if(key==='fatturazione'){
              return <div >
                <select
                  name="fatturazione"
                  id="fatturazione"
                  defaultValue={product.fatturazione}
                  style={{width:'100%'}}
                  disabled={status==="SENT"||status==="SIGNED"}
                  onChange={event => {
                    event.stopPropagation()
                    updateBillingPolicy(event, product)
                  }}
                  onClick={event => event.stopPropagation()}
                >
                  <option value="">---seleziona la fatturazione---</option>
                  <option value="50">Acconto: 50%, 30g:10%,60g:10%,90g:10%,120g:10%,180g:10%</option>
                  <option value="25">Acconto: 25%, 30g:15%,60g:15%,90g:15%,120g:15%,180g:15%</option>
                  <option value="25">Acconto: 25%, 75% ogni mese per 11 mesi</option>
                </select>
              </div>
            } else {
              return <div style={{ textTransform: "capitalize" }}><b>{key.split(/(?=[A-Z])/).join(" ")}:</b> {`${key.includes("valore") ? "€ " : ""}${
                product[key] ? product[key] : ""
              }`}</div>
            }
            }
          )}
          {/*{product.map((key) => {if(key==='valoreScontabile') {*/}
          {/*  return <th><input id={key}*/}
          {/*                    name={key} value={product['valoreContratto']} onChange={event => this.handleChangeValoreContratto(event, product)}/></th>*/}
          {/*} else if(key==='valoreContratto') {*/}
          {/*  const label = product.codprodotto.slice(0,2)*/}
          {/*  const productOriginal = this.state.contractProduct[label].find(item => item.codprodotto === product.codprodotto)*/}
          {/*  return <th>{productOriginal.valoreContratto}</th>*/}
          {/*} else {*/}
          {/*  return <th>{`${key.includes("valore") ? "€ " : ""}${*/}
          {/*    product[key] ? product[key] : ""*/}
          {/*  }`}</th>*/}
          {/*}*/}
          {/*})}*/}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  createPdf,
  getContractList,
  getContractProduct,
  saveContract,
  getContractById,
  getCompanyDataFromVat,
  getContractAddon
})(Contratto);
