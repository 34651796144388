import React, { Component } from "react";
import { CgLogOut } from "react-icons/cg";
import { isMobile } from "react-device-detect";
import logo from "../../../img/farmakom_branding.webp";

import "./ContractDispatcher.css";

class ContractDispatcher extends Component {
  logout = () => {
    localStorage.removeItem("token");
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="dispatcher">
        <img src={logo} alt={"Logo"} className="img" />
        <div className="button-container">
          <div className="">
            <button
              type="submit"
              className="btn btn-primary btn-dispatcher"
              style={{
                border: "none",
                fontWeight: "bold",
                borderRadius: isMobile ? 0 : 8,
              }}
              onClick={() => this.props.history.push("/Contratto/nuovo")}
            >
              Nuovo contratto
            </button>
          </div>
          <div className="">
            <button
              type="submit"
              className="btn btn-primary btn-dispatcher"
              style={{
                border: "none",
                fontWeight: "bold",
                borderRadius: isMobile ? 0 : 8,
              }}
              onClick={() => this.props.history.push("/ListaContratti")}
              id={"nuovo"}
            >
              Lista contratti
            </button>
          </div>
          <div className="">
            <button
              type="submit"
              className="btn btn-primary btn-dispatcher"
              style={{
                border: "none",
                fontWeight: "bold",
                borderRadius: isMobile ? 0 : 8,
              }}
              onClick={() => this.props.history.push("/RegistraVisita")}
              id={"nuovo"}
            >
              Registra visita
            </button>
          </div>
        </div>
        <div className="dispatcher-button">
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              backgroundColor: "#39fbc7",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: isMobile ? 0 : 8,
            }}
            onClick={this.logout}
          >
            <CgLogOut style={{ fontSize: 20 }} />
          </button>
        </div>
      </div>
    );
  }
}

export default ContractDispatcher;
