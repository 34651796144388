import React from 'react';
import "./Loader.css";

export const Loader = () => (
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
