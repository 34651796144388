import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Routes from "./components/routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import rootReducer from "./reducers/modules/index";
import {createStore, applyMiddleware} from "redux";
import { Provider } from "react-redux";
import reduxLogger from "redux-logger"
import thunk from "redux-thunk";
import { apiMiddleware } from 'redux-api-middleware'

const middlewares = [apiMiddleware, thunk, reduxLogger]

const store = createStore(rootReducer, applyMiddleware(...middlewares))

ReactDOM.render(
  <Provider store={store}>
    <Routes/>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
