import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./routes/Login/Login";
import ContractDispatcher from "./routes/ContractDispatcher/ContractDispatcher";
import Contratto from "./routes/Contratto/Contratto";
import ListaContratti from "./routes/ListaContratti/ListaContratti";
import RegistraVisita from './routes/RegistraVisita/RegistraVisita'
import Helmet from 'react-helmet'

class Router extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <Helmet>
          <title>Farmakom Sales</title>
          <meta name="FKS" content="FKS" />
          <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        </Helmet>
        {() => this.setState({ locationSwitch: window.location })}
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Login {...props}/>
            )}
          />
          <Route
            exact
            path="/Dispatcher"
            render={(props) => (
              <ContractDispatcher {...props}/>
            )}
          />
          <Route
            exact
            path="/Contratto/:id"
            render={(props) => (
              <Contratto {...props}/>
            )}
          />
          <Route
            exact
            path="/LIstaContratti"
            render={(props) => (
              <ListaContratti {...props}/>
            )}
          />
          <Route
            exact
            path="/RegistraVisita"
            render={(props) => (
              <RegistraVisita {...props}/>
            )}
          />

        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
