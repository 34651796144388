import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  getContractList,
  updateContract,
} from "../../../reducers/modules/Contratto";
import { isMobile } from "react-device-detect";
import moment from 'moment'

import "./ListaContratti.css";
import { FaMinus, FaPlus, FaSort } from "react-icons/fa";

class ListaContratti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      contratti: [],
      sort: "createdAt",
      sortOrder: -1,
    };
  }

  componentDidMount = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await getContractList(token);
      if (response.data) {
        // console.log("res", response.data);
        this.setState({ contratti: response.data });
      }
    }
  };

  handleChange = async (event) => {
    this.setState({ search: event.target.value });
  };

  goBack = () => {
    this.props.history.push("/Dispatcher");
  };

  filterContracts = () => {
    return this.state.contratti
      .filter((contratto) => {
        return this.state.filterStatus
          ? this.state.filterStatus.compareDate
            ? contratto.status === this.state.filterStatus.status &&
              isDate1Delta1GreaterThanDate2(
                new Date(),
                contratto.createdAt,
                this.state.filterStatus.delta
              )
            : contratto.status === this.state.filterStatus.status
          : true;
      })
      .filter((contratto) => {
        const filterable = [
          "companyName",
          "taxCode",
          "products",
          "id",
          "createdAt",
          "fkAgentName",
        ];
        const searches = this.state.search.split(" ");
        return searches.every((search) => {
          return filterable.some((field) => {
            return String(contratto[field])
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          });
        });
      });
  };

  _handleSort = (sort) => {
    if (this.state.sort === sort) {
      this.setState({
        sortOrder: this.state.sortOrder * -1,
      });
    } else {
      this.setState({
        sort,
        sortOrder: -1,
      });
    }
  };

  renderList = (isAdmin) => {
    if (isMobile) {
      return this.filterContracts()
        .sort((a, b) =>
            !a[this.state.sort]
                ?-1 * this.state.sortOrder
                :!b[this.state.sort]
                    ?+1 * this.state.sortOrder
                    : a[this.state.sort] < b[this.state.sort]
                        ? +1 * this.state.sortOrder
                        : -1 * this.state.sortOrder

        )
        .map((contratto) => (
          <ThumbnailContratto
            isAdmin={isAdmin}
            key={`contratto_${contratto.id}`}
            {...this.props}
            contratto={contratto}
          />
        ));
    }
    return (
      <table>
        <thead>
          <tr className="header-table">
            <th id="contractId">
              <div>ID contratto</div>
            </th>
            {isAdmin && (
              <th id="agent">
                <div>Agente</div>
              </th>
            )}
            <th id="status">
              <div>Stato</div>
            </th>
            <th id="companyName">
              <div>Ragione sociale</div>
            </th>
            <th id="taxCode">
              <div>P.IVA</div>
            </th>
            <th id="createdAt" onClick={() => this._handleSort("createdAt")}>
              <div>Data creazione</div>
            </th>
            <th id="signedAt" onClick={() => this._handleSort("signedAt")}>
              <div>Data firma</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.filterContracts()
            .sort((a, b) =>
                !a[this.state.sort]
                      ? -1 * (this.state.sortOrder)
                      : !b[this.state.sort]
                          ? +1 * this.state.sortOrder
                          : a[this.state.sort] > b[this.state.sort]
                                ? +1 * this.state.sortOrder
                                : -1 * this.state.sortOrder
            )
            .map((contratto) => (
              <ThumbnailContratto
                isAdmin={isAdmin}
                key={`contratto_${contratto.id}`}
                {...this.props}
                contratto={contratto}
              />
            ))}
        </tbody>
      </table>
    );
  };

  render() {
    const isAdmin =
      JSON.parse(localStorage.getItem("user")).role === "fks_admin";
    return (
      <div className="lista-contratti">
        <div className="header-container">
          <div className="page-header">Lista contratti</div>
          <div
            className="form-group search-box"
            style={isMobile ? { display: "flex", flexDirection: "column" } : {}}
          >
            <div
              style={{
                display: "flex",
                gap: 8,
                overflowX: isMobile ? "scroll" : "unset",
                maxWidth: "100%",
              }}
            >
              {this.state.filterStatus && (
                <Reset
                  handleReset={() => this.setState({ filterStatus: null })}
                />
              )}
              {contractStatuses.map((contract) => (
                <FilterStatusButton
                  handleFilter={() => this.setState({ filterStatus: contract })}
                  contract={contract}
                />
              ))}
            </div>
            <input
              id="search"
              name="search"
              type="text"
              value={this.state.search}
              className="form-control"
              onChange={this.handleChange}
              placeholder={"Cerca..."}
              style={{ width: isMobile ? "100%" : "16vw" }}
            />
          </div>
        </div>
        {this.renderList(isAdmin)}
        <div className="login-button nuovo-contratto-btn">
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              backgroundColor: "#39fbc7",
              border: "none",
              borderRadius: isMobile ? 0 : 8,
            }}
            onClick={this.goBack}
          >
            Torna indietro
          </button>
        </div>
      </div>
    );
  }
}

export default ListaContratti;

const Reset = (props) => {
  return (
    <div
      onClick={props.handleReset}
      style={{ marginLeft: 8, cursor: "pointer", fontSize: 16 }}
    >
      ↺
    </div>
  );
};

const FilterStatusButton = (props) => {
  const { status, compareDate, delta } = props.contract;
  const formattedStatus = formatStatus(status, compareDate);
  return (
    <div
      onClick={props.handleFilter}
      style={{
        backgroundColor: formattedStatus.color,
        padding: "4px 8px",
        borderRadius: isMobile ? 0 : 8,
        color: "white",
        fontSize: 14,
        cursor: "pointer",
      }}
    >
      {formattedStatus.label}
    </div>
  );
};

const ThumbnailContratto = (props) => {
  const { contratto } = props;
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState(contratto.status);
  const formattedStatus = formatStatus(contratto.status, contratto.createdAt);
  const [signDate, setSignDate] = useState(props.contratto.signedAt ? moment(props.contratto.signedAt).format("YYYY-MM-DD") : "")
  const preventProgress = (event) => {
    event.stopPropagation();
  };
  const handleChangeStatus = (event) => {
    updateContract(contratto.id, { status: event.target.value });
    setStatus(event.target.value);
  };

  const handleChangeSignedAt = (event) => {
    updateContract(contratto.id, { signedAt: event.target.value });
    setSignDate(event.target.value);
  };
  if (isMobile) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#f5f5f5",
          marginBottom: 16,
          padding: 8,
          zIndex: 100,
          fontSize: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={() =>
              props.history.push("/Contratto/" + props.contratto.id)
            }
            style={{ display: "flex", alignItems: "center", gap: 8 }}
          >
            <div
              style={{
                backgroundColor: formattedStatus.color,
                width: 16,
                height: 16,
              }}
            />
            <span
              style={{ fontWeight: "bold" }}
            >{`${props.contratto.id} - ${props.contratto.companyName}`}</span>
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            style={{ fontWeight: "bold", zIndex: 600 }}
          >
            {expanded ? <FaMinus /> : <FaPlus />}
          </div>
        </div>
        {expanded && (
          <div
            style={{ fontSize: 14, marginTop: 16 }}
            onClick={() =>
              props.history.push("/Contratto/" + props.contratto.id)
            }
          >
            {props.isAdmin && (
              <div>
                <b>Agente:</b> {props.contratto.fkAgentName}
              </div>
            )}
            <div>
              <b>PIVA:</b> {props.contratto.taxCode}
            </div>
            <div>
              <b>Prod:</b> {props.contratto.products}
            </div>
            <div>
              <b>Data creazione:</b>{" "}
              {new Date(props.contratto.createdAt).toLocaleDateString("it-IT")}
            </div>
            <div>
              <b>Data firma:</b>{" "}
              {props.contratto.signedAt
                ? new Date(props.contratto.signedAt).toLocaleDateString("it-IT")
                : ""}
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <tr style={{ fontSize: 16 }}>
      <th
        onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
      >
        {props.contratto.id}
      </th>
      {props.isAdmin && (
        <th
          onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
        >
          {props.contratto.fksAgentEmail}
        </th>
      )}
      <th
        onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
      >
        <select
          disabled={!props.isAdmin}
          onClick={preventProgress}
          onChange={handleChangeStatus}
          value={status}
          style={{
            color: formatStatus(status, props.contratto.createdAt).color,
            border: 0,
            fontWeight: "bold",
          }}
        >
          {["CREATED", "SENT", "SIGNED", "EXPIRED"].map((option) => {
            const formatted = formatStatus(option);
            return (
              <option
                style={{ color: formatted.color, fontWeight: "bold" }}
                value={option}
              >
                {formatted.label}
              </option>
            );
          })}
        </select>
      </th>
      <th
        onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
      >
        {props.contratto.companyName}
      </th>
      <th
        onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
      >
        {props.contratto.taxCode}
      </th>
      <th
        onClick={() => props.history.push("/Contratto/" + props.contratto.id)}
      >
        {new Date(props.contratto.createdAt).toLocaleDateString("it-IT")}
      </th>
      <th>
        {(signDate&&status==="SIGNED") ?<input
          type="date"
          id={"sigendAt"}
          value={signDate}
          disabled={!props.isAdmin}
          onClick={preventProgress}
          onChange={handleChangeSignedAt}
          style={{paddingLeft:8}}
        />:<div></div>}
      </th>
    </tr>
  );
};

const contractStatuses = [
  { status: "CREATED" },
  { status: "SIGNED" },
  { status: "SENT" },
  { status: "SENT", compareDate: true, delta: -30 },
];

const renderContractStatus = (status, creationDate) => {
  const formattedStatus = formatStatus(status, creationDate);
  return (
    <span style={{ color: formattedStatus.color }}>
      {formattedStatus.label}
    </span>
  );
};

const isDate1Delta1GreaterThanDate2 = (date1, date2, delta1) =>
  new Date(new Date(date1).setDate(date1.getDate() + delta1)) >=
  new Date(date2);

const formatStatus = (status, creationDate) => {
  let color = "black";
  switch (status) {
    case "SENT": {
      let label = "INVIATO";
      if (
        creationDate &&
        isDate1Delta1GreaterThanDate2(new Date(), creationDate, -30)
      ) {
        color = "red";
        label = "SCADUTO";
      } else {
        color = "orange";
      }
      return { label, color };
    }
    case "SIGNED": {
      color = "green";
      return { label: "FIRMATO", color: "#39fbc7" };
    }
    case "CREATED": {
      color = "BLUE";
      return { label: "CREATO", color: "limegreen" };
    }
    case "EXPIRED": {
      color = "BLACK";
      return { label: "SCADUTO", color: "black" };
    }
    default:
      return { label: status, color };
  }
};
