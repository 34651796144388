import React, { Component, useState } from "react";
import { isMobile } from "react-device-detect";
import "./RegistraVisita.css";
import HubspotForm from 'react-hubspot-form'

class RegistraVisita extends Component {
  render() {
    return (
      <div className="lista-contratti">
        <div className="header-container">
          <div className="page-header">Registra Visita</div>
        </div>
        <div>
          <HubspotForm
            portalId='7626421'
            formId='cf0047d4-92b4-494b-adba-fc4dc81d3d84'
            onSubmit={(event) => console.log('Submit!', event)}
            // onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </div>

        <div className="login-button nuovo-contratto-btn">
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              backgroundColor: "#39fbc7",
              border: "none",
              borderRadius: isMobile ? 0 : 8,
            }}
            onClick={() => this.props.history.push("/Dispatcher")}
          >
            Torna indietro
          </button>
        </div>
      </div>
    );
  }
}

export default RegistraVisita;
