import { API_ROOT_URL } from "../config";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const getAuthHeaders = () => {
  return {
    ...headers,
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
};

const api = {
  getContractById: (id) => {
    return {
      endpoint: `${API_ROOT_URL}/contract/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
      params: { id },
    };
  },
  getContractList: () => {
    return {
      endpoint: `${API_ROOT_URL}/contract`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getContractProduct: () => {
    return {
      endpoint: `${API_ROOT_URL}/contract/contract-products`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getContractAddon: () => {
    return {
      endpoint: `${API_ROOT_URL}/contract/contract-addon`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  updateContract: (id, data) => {
    return {
      url: `${API_ROOT_URL}/contract/update/${id}`,
      method: "PUT",
      headers: getAuthHeaders(),
      data,
    };
  },
  saveContract: (data) => {
    return {
      endpoint: `${API_ROOT_URL}/pdf/create`,
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    };
  },
  savePriceQuotation: (data) => {
    return {
      endpoint: `${API_ROOT_URL}/pdf/price-quotation`,
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    };
  },
  sendContract: (contractId) => {
    return {
      endpoint: `${API_ROOT_URL}/pdf/send/${contractId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  deleteContract: (contractId) => {
    return {
      endpoint: `${API_ROOT_URL}/contract/delete/${contractId}`,
      method: "DELETE",
      headers: getAuthHeaders(),
    };
  },
  remindContract: (contractId) => {
    return {
      endpoint: `${API_ROOT_URL}/pdf/remind/${contractId}`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  loadContractBySaler: (salerId) => {
    return {
      endpoint: `${API_ROOT_URL}/pdf/load-contract-by-saler`,
      method: "POST",
      headers: headers,
    };
  },
  login: (dataLogin) => {
    return {
      endpoint: `${API_ROOT_URL}/agents/login`,
      method: "POST",
      headers: headers,
      data: JSON.stringify(dataLogin),
    };
  },
  getAgent: (token) => {
    return {
      endpoint: `${API_ROOT_URL}/agents/get`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getCompanyData: (vat) => {
    return {
      endpoint: `${API_ROOT_URL}/atoka`,
      method: "GET",
      headers: getAuthHeaders(),
      params: {
        token: "25059d61-87b6-4285-a952-d88d4c8fd650-e",
        fields: "items",
        vat: vat,
        package: "*",
        countries: "it",
      },
    };
  },
  regenerateContractTask: (contractId) => {
    return {
      endpoint: `${API_ROOT_URL}/contract/regenerate/${contractId}`,
      method: "PUT",
      headers: getAuthHeaders(),
    };
  },
};

export default api;
