import { RSAA } from "redux-api-middleware";
import api from "../../api";
import axios from "axios";

export const GET_REQUEST = "farmakom/contract/GET_REQUEST";
export const GET_SUCCESS = "farmakom/contract/GET_SUCCESS";
export const GET_FAILURE = "farmakom/contract/GET_FAILURE";

const LOAD_BY_SALER_REQUEST = "farmakom/contract/LOAD_BY_SALER_REQUEST";
const LOAD_BY_SALER_SUCCESS = "farmakom/contract/LOAD_BY_SALER_SUCCESS";
const LOAD_BY_SALER_FAILURE = "farmakom/contract/LOAD_BY_SALER_FAILURE";

const SAVE_REQUEST = "farmakom/contract/SAVE_REQUEST";
const SAVE_SUCCESS = "farmakom/contract/SAVE_SUCCESS";
const SAVE_FAILURE = "farmakom/contract/SAVE_FAILURE";

const UPDATE_REQUEST = "farmakom/contract/UPDATE_REQUEST";
const UPDATE_SUCCESS = "farmakom/contract/UPDATE_SUCCESS";
const UPDATE_FAILURE = "farmakom/contract/UPDATE_FAILURE";

export const GET_PDF_REQUEST = "farmakom/sales/pdf/GET_PDF_REQUEST";
export const GET_PDF_SUCCESS = "farmakom/sales/pdf/GET_PDF_SUCCESS";
export const GET_PDF_FAILURE = "farmakom/sales/pdf/GET_PDF_FAILURE";

export const CREATE_PDF_REQUEST = "farmakom/sales/pdf/CREATE_PDF_REQUEST";
export const CREATE_PDF_SUCCESS = "farmakom/sales/pdf/CREATE_PDF_SUCCESS";
export const CREATE_PDF_FAILURE = "farmakom/sales/pdf/CREATE_PDF_FAILURE";

export const GET_COMPANY_DATA_REQUEST =
  "farmakom/sales/pdf/GET_COMPANY_DATA_REQUEST";
export const GET_COMPANY_DATA_SUCCESS =
  "farmakom/sales/pdf/GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_DATA_FAILURE =
  "farmakom/sales/pdf/GET_COMPANY_DATA_FAILURE";

// API CALL

export function createPdf(data) {
  return {
    [RSAA]: {
      ...api.createPdf({ data }),
      types: [CREATE_PDF_REQUEST, CREATE_PDF_SUCCESS, CREATE_PDF_FAILURE],
    },
  };
}

export function getPdf(data) {
  return {
    [RSAA]: {
      ...api.createPdf({ data }),
      types: [GET_PDF_REQUEST, GET_PDF_SUCCESS, GET_PDF_FAILURE],
    },
  };
}

export function saveContract(data, isPriceQuotation) {
  // console.log("data",data)
  if (isPriceQuotation) {
    return {
      [RSAA]: {
        ...api.savePriceQuotation(data),
        types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_FAILURE],
      },
    };
  }

  return {
    [RSAA]: {
      ...api.saveContract(data),
      types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_FAILURE],
    },
  };
}

export function loadContractBySaler(salerId) {
  return {
    [RSAA]: {
      ...api.loadContractBySaler(salerId),
      types: [
        LOAD_BY_SALER_REQUEST,
        LOAD_BY_SALER_SUCCESS,
        LOAD_BY_SALER_FAILURE,
      ],
    },
  };
}

export async function regenerateContractTask(contractId){
  const { endpoint, method, headers } = api.regenerateContractTask(contractId);
  try {
    const res = await axios({
      url: endpoint,
      method,
      headers,
    })

    if (res.status === 200) {
      return true
    }
  } catch (e) {
    // console.log(e)
    return false
  }
}

export const getContractList = async (token) => {
  const { endpoint, method, headers } = api.getContractList(token);
  try {
    const res = await axios({
      url: endpoint,
      method: method,
      headers: headers,
    });
    if (res.status === 200) {
      // console.log(res)
      return res;
    }
  } catch (e) {
    // console.log(e);
    return e;
  }
};

export const updateContract = async (id, updates) => {
  const { url, method, headers, data } = api.updateContract(id, updates);
  try {
    const res = await axios({
      url,
      method,
      headers,
      data,
    });
    if (res.status === 200) {
      // console.log(res)
      return res;
    }
  } catch (e) {
    // console.log(e);
    return e;
  }
};

export function getContractById(id) {
  return async function (dispatch) {
    const { endpoint, method, headers } = api.getContractById(id);
    try {
      const res = await axios({
        url: endpoint,
        method: method,
        headers: headers,
      });
      if (res.status === 200) {
        // console.log(res)
        return res;
      }
    } catch (e) {
      // console.log(e);
      return e;
    }
  };
}

export function getContractProduct() {
  return async function (dispatch) {
    const { endpoint, method, headers } = api.getContractProduct();
    try {
      const res = await axios({
        url: endpoint,
        method: method,
        headers: headers,
      });
      if (res.status === 200) {
        // console.log(res)
        return res;
      }
    } catch (e) {
      // console.log(e);
      return e;
    }
  };
}

export function getContractAddon(token) {
  return async function (dispatch) {
    const { endpoint, method, headers } = api.getContractAddon();
    try {
      const res = await axios({
        url: endpoint,
        method: method,
        headers: headers,
      });
      if (res.status === 200) {
        // console.log(res)
        return res;
      }
    } catch (e) {
      // console.log(e);
      return e;
    }
  };
}


export const getCompanyDataFromVat = async (vat) => {
  try {
    const { endpoint, method, headers, params } = api.getCompanyData(vat);
    const res = await axios({
      url: endpoint,
      method,
      headers,
      params,
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    // console.log(e);
    return e;
  }
};

// //////////////
// //Reducers
// /////////////

const initialState = {
  loading: false,
  saving: false,
  error: "",
};

export default function contrattoReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
      };
    case CREATE_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CREATE_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
      };
    case GET_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case SAVE_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          ...action.payload,
        },
        error: null,
      };
    case SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.payload.message,
      };
    case LOAD_BY_SALER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_BY_SALER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case LOAD_BY_SALER_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
}
