import { RSAA } from "redux-api-middleware";
import api from "../../api";

import axios from "axios";
import Boom from "boom";
import data from "bootstrap/js/src/dom/data";

const LOGIN_REQUEST = "farmakom/login/LOGIN_REQUEST";
const LOGIN_SUCCESS = "farmakom/login/LOGIN_SUCCESS";
const LOGIN_FAILURE = "farmakom/login/LOGIN_FAILURE";

// API CALL
export const login = async (loginData) => {
  // console.log("called", loginData);
  if (loginData.email && loginData.password) {
    try {
      const { endpoint, method, headers, data } = api.login(loginData);
      const res = await axios({
        url: endpoint,
        method,
        headers,
        data,
      });
      // console.log("res", res);
      if (res.status === 200) {
        localStorage.setItem("token", res.data.authToken);
        localStorage.setItem("user", JSON.stringify(res.data.user));
      }
      return res;
    } catch (e) {
      // console.log(e);
      const res = { status: 401 };
      return res;
    }
  }
};

export function getAgent(token) {
  return async function (dispatch) {
    const { endpoint, method, headers } = api.getAgent(token);
    try {
      const res = await axios({
        url: endpoint,
        method: method,
        headers: headers,
      });
      if (res.status === 200) {
        // console.log(res);
        return res;
      }
    } catch (e) {
      // console.log(e);
      const res = { status: 401 };
      return res;
    }
  };
}

export function authUser(user) {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
}

export function authError(errorMessage) {
  return {
    type: LOGIN_FAILURE,
    payload: errorMessage,
  };
}

// //////////////
// //Reducers
// /////////////

const initialState = {
  loading: false,
  saving: false,
  error: "",
  // data: {
  //   listPrice: 0,
  //   price: 0,
  //   discounts: [],
  //   tags: [],
  //   similars: [],
  // },
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
          // ...action.type
        },
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
}
